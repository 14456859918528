<template>
  <div
    id="document-listing"
    class="ml-8"
    v-if="$store.state.auth.user.roles[0].name"
  >
    <div class="justify-between">
      <div>
        <h1 class="appointments text-justify text-xl mt-12 text-appointments">
          Document
        </h1>
      </div>
      <div class="sm:flex mt-12 mx-auto block md;flex float-right mr-8">
        <Search
          class="mb-4 float-right"
          label="Search by name"
          @input="updateQuery"
        />
      </div>
    </div>

    <div v-if="elementsAvailable">
      <table>
        <thead>
          <tr
            class="flex flex-col flex-no wrap table-row rounded-l-lg rounded-none mb-2 mb-0"
          >
            <th class="p-3 text-left xl:text-center invisible">
              <input type="checkbox" @change="generalCheck($event)" />
            </th>

            <th v-if="fields['name']" class="p-3 text-left">
              <span class="flex text-table text-xs">Document</span>
            </th>
            <th v-if="fields['dateAndTime']" class="p-3 text-left">
              <span class="flex text-table text-xs">Date of Submission</span>
            </th>
            <th v-if="fields['description']" class="p-3 text-left">
              <span class="flex sm:flex text-table text-xs text-left"
                >Description</span
              >
            </th>
            <th v-if="fields['institution']" class="p-3 text-left">
              <span class="flex sm:flex text-table text-xs text-left"
                >Institution</span
              >
            </th>
            <th v-if="fields['cluster']" class="p-3 text-left">
              <span class="flex text-table text-xs">Cluster</span>
            </th>
            <th v-if="fields['status']" class="p-3 text-left">
              <span class="flex text-table text-xs">Status</span>
            </th>
            <th class="p-3 text-left text-table text-xs flex justify-between">
              <!-- <p class="-ml-14 xl:-ml-3">Actions</p> -->
              <DocumentActionButtons
                @deleted="removedDeletedItems"
                v-if="selectedDocuments.length"
                :showIcon="true"
                :deleteFill="`${
                  selectedDocuments.length ? '#4296BD' : '#B0B0B0'
                }`"
                :editFill="`${
                  selectedDocuments.length ? '#FB2222' : '#B0B0B0'
                }`"
                :id="selectedDocuments"
                ACTION="DOCUMENT"
              />
            </th>
          </tr>
        </thead>

        <tbody class="sm:flex-1 sm:flex-none">
          <tr
            v-for="document in documents"
            :key="document.id"
            class="sm:flex sm:flex-col sm:flex-no sm:wrap sm:table-row sm:mb-2 sm:mb-0 h-80 main-header sm:header sm:shadow-lg"
          >
            <!-- <td
              :class="{
                invisible:
                  $store.state.auth.user.roles[0].name !== 'CLUSTER_ADMIN',
              }"
              class="p-3 text-left"
            >
              <input
                type="checkbox"
                :checked="selectedDocuments[0] == document.id"
                @change="onChange(document.id)"
              />
            </td> -->
            <td v-if="fields['name']" class="p-3 flex text-left">
              <div class="flex place-items-center">
                <img
                  v-if="document.file"
                  :src="`/icons/${
                    document.file.name.includes('zip')
                      ? 'zip.svg'
                      : document.file.name.includes('doc') ||
                        document.file.name.includes('docx')
                      ? 'docx.png'
                      : document.file.name.includes('pdf')
                      ? 'pdf.png'
                      : document.file.name.includes('xlsx')
                      ? 'xlsx.png'
                      : document.file.name.includes('pptx') ||
                        document.file.name.includes('ppt')
                      ? 'pptx.png'
                      : ''
                  }`"
                  @click="download(document.file.url)"
                  alt=""
                  class="mt-1.5 w-6 cursor-pointer"
                />
                <p
                  class="md:ml-2 md:mt-2 text-xs sm:text-values title mt-2 sm:mt-0 pl-2 sm:pl-0"
                >
                  {{ document.title }}
                </p>
              </div>
            </td>

            <td
              v-if="fields['dateAndTime']"
              class="text-values text-xs p-3 text-left"
            >
              <p class="">{{ document.createdAt }}</p>
            </td>

            <td v-if="fields['description']" class="p-3 text-left description">
              <span
                class="ml-7 sm:ml-0 sm:text-values sm:text-sm sm:title short"
                >{{ trimString(document.description, 30) }}</span
              >
              <!--              <span class="dots">...</span>-->
              <span
                class="ml-7 sm:ml-0 sm:text-values sm:text-sm sm:title long"
                >{{
                  document.description.substring(
                    30,
                    document.description.length
                  )
                }}</span
              >
            </td>
            <td v-if="fields['institution']" class="p-3 text-left">
              <span class="ml-7 sm:ml-0 sm:text-values sm:text-sm sm:title">{{
                document.institution.name
              }}</span>
            </td>
            <td v-if="fields['cluster']" class="p-3 sm:font-semibold">
              <div class="sm:w-28 sm:h-5 sm:p-0.5 bg-table text-center">
                <span class="sm:text-values sm:text-sm sm:title">{{
                  document.cluster.name
                }}</span>
              </div>
            </td>
            <td v-if="fields['status']" class="p-3 sm:font-semibold">
              <div class="w-28 sm:h-4 sm:p-0.5 bg-white text-center">
                <span class="sm:text-values sm:text-xs sm:title">{{
                  document.status
                }}</span>
              </div>
            </td>

            <td class="text-values text-sm p-3">
              <div
                class="w-16 rounded text-view h-7 bg-table p-0.5 pt-1 pb-1 cursor-pointer text-center mb-4"
                @click="$router.push(`/documents/${document.id}/timeline`)"
              >
                <span class="view">View</span>
              </div>
              <div
                class="w-16 rounded text-view h-7 bg-table p-0.5 pt-1 pb-1 -mt-7 sm:mt-0 cursor-pointer text-center"
                @click="$router.push(`/documents/${document.id}/edit`)"
              >
                <span class="view cursor-pointer">Edit</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ElementsNotFound :isLoading="loading" type="Documents" />
  </div>
</template>
<script>
import DocumentActionButtons from "/src/components/Listings/DocumentActionButtons.vue";
import Search from "/src/components/searchInput.vue";
import Api from "/src/services/Apis";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import { trimString } from "/src/functions";
import { mapActions } from "vuex";

export default {
  name: "DocumentDetails",
  components: {
    DocumentActionButtons,
    Search,
    ElementsNotFound,
  },

  data() {
    return {
      toShow: false,
      checkDisabled: false,
      numberOfChecks: 0,
      editColor: "#FB2222",
      elementsAvailable: true,
      documents: [],
      selectedDocuments: [],
      clusterId: "",
      clusters: [],
      loading: true,
      institutions: [],
      status: [
        { id: 1, name: "DRAFT" },
        { id: 2, name: "ANALYSIS_IS_DONE" },
        { id: 3, name: "ON_HOLD" },
        { id: 4, name: "SENT_BACK" },
        { id: 5, name: "RETURNED" },
        { id: 6, name: "APPROVED_IMCC" },
        { id: 7, name: "APPROVED_CABINET" },
        { id: 8, name: "APPROVED_PARLIAMENT" },
        { id: 9, name: "APPROVED_CLUSTER" },
        { id: 10, name: "GAZETTED " },
      ],
      documentName: "",
      statusQuery: "APPROVED_CABINET",
      query: "",
      fields: [],
    };
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    updateQuery(value) {
      this.query = value;
      this.searchDocument();
    },

    trimString,
    removedDeletedItems() {
      this.documents = this.documents.filter(
        (e) => e.id != this.selectedDocuments[0]
      );
      this.selectedDocuments = [];
      this.$store.dispatch("notification/SET_NOTIFICATION", {
        msg: "Document deleted sucessfully",
        uptime: 3000,
      });
    },
    async getDocuments() {
      await Api.get("documents").then((response) => {
        this.documents = response.data.content;
        if (!this.documents.length) {
          this.elementsAvailble = false;
        } else {
          this.elementsAvailble = true;
        }
        this.loading = false;
      });
    },
    download(url) {
      location.href = url;
    },
    generalCheck(e) {
      if (e.target.checked) {
        this.numberOfChecks++;
      } else {
        this.numberOfChecks--;
      }
      if (this.numberOfChecks > 0) {
        for (let document of this.documents) {
          this.selectedDocuments.push(document.id);
        }
      } else {
        this.selectedDocuments = [];
      }
    },
    onChange(e) {
      let index = this.selectedDocuments.indexOf(e);

      if (index > -1) {
        this.selectedDocuments = [];
      } else {
        this.selectedDocuments = [e];
      }
    },
    async getDocumentByClusterId(value) {
      if (value !== "") {
        if (value === "All") {
          this.getDocuments();
        } else {
          await Api.get("documents/cluster/" + value).then((resp) => {
            this.documents = resp.data.content;
            if (!this.documents.length) {
              this.elementsAvailable = false;
            } else {
              this.elementsAvailable = true;
            }
          });
        }
      }
    },
    async getDocumentByInstitutionId(value) {
      if (value !== "") {
        if (value === "All") {
          this.getDocuments();
        } else {
          await Api.get("documents/institution/" + value).then((resp) => {
            this.documents = resp.data.content;
            if (!this.documents.length) {
              this.elementsAvailble = false;
            } else {
              this.elementsAvailble = true;
            }
          });
        }
      }
    },
    async searchDocument() {
      await Api.get(
        `documents/search-many/cluster/ALL/institution/ALL/status/${
          this.statusQuery === "" ? "ALL" : this.statusQuery
        }?name=${this.query === "" ? "" : this.query}&limit=1000&page=0`
      )
        .then((resp) => {
          this.documents = resp.data.content;
          if (!this.documents.length) {
            this.elementsAvailable = false;
          } else {
            this.elementsAvailable = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAllClusters() {
      await Api.get("clusters")
        .then((resp) => {
          this.clusters = resp.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAllInstitutions() {
      await Api.get("institutions")
        .then((response) => {
          this.institutions = response.data.content;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDocumentByStatus(value) {
      if (value !== "") {
        if (value === "All") {
          this.getDocuments();
        } else {
          await Api.get("documents/status/" + value).then((resp) => {
            this.documents = resp.data.content;
            console.log(this.documents.length);
            if (!this.documents.length) {
              this.elementsAvailable = false;
            } else {
              this.elementsAvailable = true;
            }
          });
        }
      }
    },
  },

  async created() {
    this.fields = await this.getFields("documents_table");
    // this.getAllClusters();
    // this.getAllInstitutions();
    // this.searchDocument();
    this.getDocumentByStatus("APPROVED_CABINET");
  },
};
</script>

<style lang="scss">
#document-listing {
  table {
    width: 97%;
    border-collapse: collapse;
  }
  .search-area {
    background: url("/icons/search.svg");
    background-position: 10rem 8px;
    background-repeat: no-repeat;
    background-color: white;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 15px;
  }
  .title {
    font-weight: 600;
  }
  .header {
    background: white;
    height: 3.5rem;
  }
  .names {
    margin: 0 3rem 0 -8rem;
  }
  td.description span {
    font-size: 11.5px !important;
    //&.short{
    //  display: none;
    //}
    //&.long:hover + &.short{
    //  display: initial;
    //}
    //&.short:hover + &.short{
    //  display: none;
    //}
  }
  .description {
    .long {
      display: none;
    }

    .short:hover + .long {
      display: initial;
    }
    //.short:hover + .dots {
    //  display: none;
    //}
  }
  @media only screen and (max-width: 760px),
    (min-device-width: 250px) and (max-device-width: 640px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      background: white;
      width: 20rem;
      margin: 1rem 0 0 0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-size: 12px;
      text-align: justify;
      color: #000000;
      padding: 1rem;
    }

    td:nth-of-type(2):before {
      content: "Document";
    }
    td:nth-of-type(3):before {
      content: "Institution";
    }
    td:nth-of-type(4):before {
      content: "Date of Submission";
    }
    td:nth-of-type(5):before {
      content: "CLuster";
    }
    td:nth-of-type(6):before {
      content: "Status";
    }
    td:nth-of-type(7):before {
      content: "Actions";
    }
  }
}
</style>
